import AgentTabEdit from "./agentTabEdit";
import AgentTabFav from "./agentTabFav";
import AgentTabMoodBoard from "./agentTabMoodboard";
import { useHistory, useLocation } from "react-router-dom";
import AgentMoodBoardEdit from "./agentMoodBoardEdit";
import { useEffect, useState } from "react";
import { getMoodBoardsSharedWithMe } from "../../../AxiosFunctions/Axiosfunctionality";
import loadingImage from "../../../assets/loading_trasnparent.gif";

const AgentProfile = ({
  searchedAgentDetails,
  haveEditAccess,
  agentDetails,
}) => {
  const [sharedMoodBoards, setSharedMoodBoards] = useState([]);
  const [selectedMoodBoard, setSelectedMoodBoard] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedMoodBoardEditAccess, setSelectedMoodBoardEditAccess] =
    useState(false);
  const tabsAgent = [
    { name: "Favorite Artists", path: "favoriteArtists",tooltip:"" },
    { name: "Idea Board", path: "ideaBoard",tooltip:"Create some ideaboards" },
    { name: "Shared Idea Board", path: "sharedIdeaBoard", tooltip:"Share some of your ideas." },
  ];
  const history = useHistory();
  const location = useLocation();

  // Function to parse query parameters
  const getQueryParam = (param) => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(param);
  };
  // Get the 'activeTab' query parameter
  const activeTab = getQueryParam("activeTab");
  const moodBoardId = getQueryParam("ideaBoardId");

  const handleTab = (tab, moodBoardId = null, sharedByUserId = null) => {
    if (tab === "ideaBoard" || tab === "sharedIdeaBoard") {
      setLoading(true);
    }
    const agentId = sharedByUserId || agentDetails._id;
    let url = `/myshannon/${agentId}/?activeTab=${tab}`;

    if (moodBoardId) {

      url += `&ideaBoardId=${moodBoardId}`;
    }

    history.push(url);
    setTimeout(() => {
      setLoading(false); // Stop loading after the URL is updated
    }, 500); // Simulate a delay for URL update}
  };
  useEffect(() => {
    const checkAccess = setTimeout(() => {
      if (activeTab === "edit" && !haveEditAccess) {
        history.push(`/`);
      }
    }, 1000); // Delay for 1 second (1000 milliseconds)

    return () => clearTimeout(checkAccess); // Clear the timeout if the component unmounts or dependencies change
  }, [activeTab, haveEditAccess]);
  const fetchSharedIdeaBoards = async () => {
    try {
      const response = await getMoodBoardsSharedWithMe({
        userId: agentDetails._id,
      });
      setSharedMoodBoards(response.data.moodBoards);
    } catch (error) {
      console.error("Error fetching shared idea boards", error);
    }
  };
  useEffect(() => {
    if (agentDetails && agentDetails._id) {
      fetchSharedIdeaBoards();
    }
  }, [agentDetails]);
  useEffect(() => {
    if (
      agentDetails &&
      moodBoardId &&
      sharedMoodBoards.length > 0 &&
      activeTab === "sharedIdeaBoard"
    ) {
      const moodBoard = sharedMoodBoards.find((mb) => mb._id === moodBoardId);
      setSelectedMoodBoard(moodBoard);

      setSelectedMoodBoardEditAccess(
        haveEditAccess ||
          (moodBoard?.sharedEditEmails &&
            moodBoard.sharedEditEmails.includes(agentDetails.email))
      );
    }
  }, [moodBoardId, sharedMoodBoards, haveEditAccess, agentDetails]);
  function renderTabContent(activeTab, moodBoardId) {
    switch (activeTab) {
      case "edit":
        return (
          <AgentTabEdit
            agentDetails={searchedAgentDetails}
            haveEditAccess={haveEditAccess}
          />
        );
      case "favoriteArtists":
        return (
          <AgentTabFav
            agentID={searchedAgentDetails?._id}
            haveEditAccess={haveEditAccess}
          />
        );
      case "ideaBoard":
        return moodBoardId ? (
          <AgentMoodBoardEdit
            moodBoardId={moodBoardId}
            searchedAgentDetails={searchedAgentDetails}
            agentDetails={agentDetails}
            haveEditAccess={haveEditAccess}
            moodBoards={searchedAgentDetails.moodBoards}
          />
        ) : (
          <AgentTabMoodBoard
            handleTab={handleTab}
            searchedAgentDetails={searchedAgentDetails}
            agentDetails={agentDetails}
            haveEditAccess={haveEditAccess}
            moodBoards={searchedAgentDetails.moodBoards}
          />
        );
      case "sharedIdeaBoard":
        return moodBoardId && selectedMoodBoard ? (
          <AgentMoodBoardEdit
            moodBoardId={selectedMoodBoard._id}
            searchedAgentDetails={searchedAgentDetails}
            agentDetails={agentDetails}
            haveEditAccess={selectedMoodBoardEditAccess}
            moodBoards={sharedMoodBoards}
            fetchSharedIdeaBoards={fetchSharedIdeaBoards}
          />
        ) : (
          <AgentTabMoodBoard
            handleTab={handleTab}
            searchedAgentDetails={searchedAgentDetails}
            agentDetails={agentDetails}
            haveEditAccess={false}
            moodBoards={sharedMoodBoards}
          />
        );
      default:
        return null;
    }
  }
  return (
    <div>
      {
        ((agentDetails && agentDetails._id) || haveEditAccess) && activeTab !== "edit" && 
        <h2 className="newsh2 h">
          Welcome to{" "}
          <span style={{ color: "rgb(250, 142, 55)" }}>MyShannon</span>
        </h2>
      }
      <div className="agentDashboardGrid">
        {((agentDetails && agentDetails._id) || haveEditAccess) &&
          activeTab !== "edit" && (
            <div style={{ marginBottom: "15px" }}>
              {tabsAgent.map((tab, i) => (
                <button
                  key={i}
                  title={tab.tooltip}
                  className={`allArtistsButtons ${
                    activeTab === tab.path ? "allArtistsButtonsActive" : ""
                  } `}
                  onClick={() => handleTab(tab.path)}
                >
                  {tab.name}
                </button>
              ))}
            </div>
          )}
        {loading ? (
          <div className="" style={{ width: "full", textAlign: "center" }}>
            <img alt="loading" src={loadingImage} style={{ width: "50px" }} />
          </div> // Display loader here under the tabs
        ) : (
          <>{renderTabContent(activeTab, moodBoardId)}</>
        )}
      </div>
    </div>
  );
};

export default AgentProfile;
