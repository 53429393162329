import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IMAGE_ROUTE } from "../../AxiosFunctions/Axiosfunctionality";
import { artistKeyword } from "../../redux/artistImageKeywordDataSlice";
import loading from "../../assets/loading.gif";
import DUMMY from "../../assets/img/1695387962634--sub_image_0.jpg";

const images = window.location.origin + "/assets/images";

function Categories(props) {
  const [tempArtist, setTempArtist] = useState([]);
  const [filterCond, setFilterCond] = useState(true);
  const [filterHighlighted, setFilterHighlighted] = useState(null);

  const dispatch = useDispatch();
  const { artistImageKeywordDataSlice } = useSelector((state) => state);

  function randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const filterChange = (filter) => {
    // let tempData = [...data];
    // setDataOriginal([...data])
    if (filter === "A-Z") {
      let temp = [];
      let data = [...artistImageKeywordDataSlice.artistKeywordImages];

      setFilterCond(false);

      temp = data.sort((a, b) =>
        a.keyword.normalize().localeCompare(b.keyword.normalize())
      );

      // artistImageKeywordDataSlice.artistKeywordImages.map((val, ind) => {
      //   let tempImage = [...val.ImageData]
      //   tempImage = tempImage.sort((a, b) => a.keyword.normalize().localeCompare(b.keyword.normalize()));
      //   temp.push({...val,ImageData:tempImage})
      // })
      setFilterHighlighted(2);
      setTempArtist(temp);
      // tempData = tempData.sort((a, b) => a.artistId.firstname.normalize().localeCompare(b.artistId.firstname.normalize()));
    } else {
      setFilterHighlighted(1);
      setFilterCond(true);
      // tempData = [...dataOriginal];
      // tempData = dataOriginal;
      // setData(tempData);
    }
  };

  useEffect(() => {
    dispatch(artistKeyword({ type: 1 }));
    localStorage.setItem("Category", "");
  }, []);

  return (
    <>
      <div class="sortingcont right pt-0  me-0 ">
        <div className="d-flex  ">
          <a
            class={
              filterHighlighted == 1
                ? "filter-button sort-active w-inline-block  mt-0"
                : filterHighlighted == 2
                ? "filter-button w-inline-block  mt-0"
                : "filter-button sort-active w-inline-block  mt-0"
            }
            style={{ marginLeft: "0px" }}
            onClick={() => filterChange("Default")}
          >
            <div>FEATURED</div>
          </a>
          <a
            class={
              filterHighlighted == 2
                ? "filter-button sort-active mt-0 me-0"
                : "filter-button mt-0 me-0"
            }
            onClick={() => filterChange("A-Z")}
          >
            <div>ALPHABETICAL A-Z</div>
          </a>
        </div>
      </div>
      <div className="_2cols2_">
        {props.children}
        <div
          id="w-node-_6f42e407-456f-5b2f-82e4-417072db3669-84f2d081"
          className="divisionscolumn"
        >
          <div
            id="w-node-_429c632c-0632-16be-f5b5-f2b7200da64a-84f2d081"
            className="divisioncontainer"
          >
            {artistImageKeywordDataSlice.loading ? (
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <img className="mb-2" alt="loading" src={loading} />
              </div>
            ) : artistImageKeywordDataSlice.artistKeywordImages !==
              undefined ? (
              filterCond ? (
                <div
                  id="w-node-f734ee66-0b58-4c14-e08b-49ceded015c9-84f2d081"
                  className="detail_card3 "
                  // style={{ paddingTop: "10px" }}
                >
                  {artistImageKeywordDataSlice.artistKeywordImages.map(
                    (item, key) => (
                      <>
                        <>
                          <Link
                            key={key}
                            id="w-node-f734ee66-0b58-4c14-e08b-49ceded015ca-84f2d081"
                            to={
                              item.keyword.includes("/")
                                ? "/categories/" +
                                  item.keyword.replace(/\//g, "_")
                                : "/categories/" +
                                  item.keyword.replace(/\s/g, "_")
                            }
                            className="artistcard w-inline-block"
                            onClick={() => {
                              localStorage.setItem(
                                "Category",
                                item.keyword == "3D Rendering"
                                  ? "3D Rendering"
                                  : item.keyword.charAt(0).toUpperCase() +
                                      item.keyword.slice(1)
                              );
                            }}
                          >
                            <img
                              src={String(
                                item.imagePath == "Dummy"
                                  ? DUMMY
                                  : item.imagePath
                              )}
                              loading="lazy"
                              alt=""
                              className="image"
                            />
                            <div className="artistnamediv">
                              <div className="artistnametext-v3">
                                {item.keyword.toUpperCase()}
                              </div>
                            </div>
                          </Link>
                        </>
                      </>
                    )
                  )}
                </div>
              ) : (
                <div
                  id="w-node-f734ee66-0b58-4c14-e08b-49ceded015c9-84f2d081"
                  className="detail_card3 "
                >
                  {tempArtist.map((item, key) => (
                    <>
                      <>
                        <Link
                          key={key}
                          id="w-node-f734ee66-0b58-4c14-e08b-49ceded015ca-84f2d081"
                          to={
                            item.keyword.includes("/")
                              ? "/categories/" +
                                item.keyword.replace(/\//g, "_")
                              : "/categories/" +
                                item.keyword.replace(/\s/g, "_")
                          }
                          className="artistcard w-inline-block"
                        >
                          <img
                            src={String(
                              item.imagePath == "Dummy" ? DUMMY : item.imagePath
                            )}
                            loading="lazy"
                            alt=""
                            className="image"
                            onClick={() => {
                              localStorage.setItem(
                                "Category",
                                item.keyword == "3D Rendering"
                                  ? "3D Rendering"
                                  : item.keyword.charAt(0).toUpperCase() +
                                      item.keyword.slice(1)
                              );
                            }}
                          />
                          <div className="artistnamediv">
                            <div className="artistnametext-v3">
                              {item.keyword.toUpperCase()}
                            </div>
                          </div>
                        </Link>
                      </>
                    </>
                  ))}
                </div>
              )
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Categories;
