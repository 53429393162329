import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  getAllMedical,
  getCategoryTypeOne,
  getArtistCategoryTypeOne,
  getGraphicCovers,
  getBipocBlack,
  getBipocAsian,
  getBipocLatino,
  getBipocCentralAsia,
  getBipocIndigenous,
  getAllProperties,
  getMotions,
} from "../../AxiosFunctions/Axiosfunctionality";
import {
  sortAlphaOrder,
  sortAlphaOrderKeyword,
} from "../../UserServices/Services";

function DivisionSideBar(props) {
  const { pages, search: search2 } = useParams();
  const { ArtistDataAPI } = useSelector((state) => state);
  const [keywordReducer, setKeywordReducer] = useState([]);
  const [artistData, setArtistData] = useState([]);
  const [bipocMenuOpen, setBipocMenuOpen] = useState();
  const [graphicMenuOpen, setGraphicMenuOpen] = useState(false);
  const [medicalMenuOpen, setMedicalMenuOpen] = useState(false);
  let currArtist = "";
  const sortingSeq = [
    "COVERS",
    "SEQUENTIAL",
    "INKING",
    "COLORING",
    "LETTERING",
  ];
  const sortingSeqMedical = ["GENERAL", "TECHNICAL"];
  const pagesWithSideMenu = [
    "Illustration-artists",
    "Photography",
    "Medical",
    "Motion",
    "Divisions",
    "Illustration",
  ];
  const bipocPagesCurrentStyle = [
    "Black",
    "Asian",
    "Latino",
    "Central Asia",
    "Indigenous",
    "Black",
    "AsianArtist",
    "LatinoArtist",
    "CentralAsianArtist",
    "IndigenousArtist",
    "black",
    "asianArtist",
    "latinoArtist",
    "centralAsianArtist",
    "indigenousArtist",
    "BIPOC",
    "Latino/Latina",
  ];
  const search2List = [
    "Action_Adventure",
    "Children's Books_Products",
    "Vintage_Retro",
    "Sci-fi_Fantasy",
    "Beauty_Fashion",
    "Editorial_Conceptual",
    "Nature_Landscape",
    "Painterly_Traditional",
    "Portrait_Likeness",
  ];
  const isGraphical = [
    "covers",
    "sequential",
    "inking",
    "coloring",
    "lettering",
    "GRAPHIC NOVEL",
  ];
  const isMedical = ["general", "technical", "MEDICAL"];
  let alpha = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  useEffect(() => {
    if (
      props.activeBtn === "illustration-artists" ||
      props.activeBtn === "divisions"
    ) {
      //|| props.activeBtn === "detailedPage"
      getArtistCategoryTypeOne({ keyword: "ILLUSTRATION" }).then((res) => {
        setArtistData(
          sortAlphaOrder(res !== undefined ? (res.length > 0 ? res : []) : [])
        );
      });
    } else if (props.activeBtn === "cgi") {
      getArtistCategoryTypeOne({ keyword: "3D Rendering", type: 1 }).then(
        (res) => {
          setArtistData(
            sortAlphaOrder(res !== undefined ? (res.length > 0 ? res : []) : [])
          );
        }
      );
    } else if (props.activeBtn === "photography") {
      getArtistCategoryTypeOne({ keyword: "Photography", type: 1 }).then(
        (res) => {
          setArtistData(
            sortAlphaOrder(res !== undefined ? (res.length > 0 ? res : []) : [])
          );
        }
      );
    } else if (
      isMedical.includes(props.activeBtn) ||
      props.activeBtn === "medical"
    ) {
      setMedicalMenuOpen(true);
      getAllMedical().then((res) => {
        let temp = [];
        for (let index = 0; index < res.length; index++) {
          let orderedArtist = res[index].data?.sort((a, b) => {
            if (
              a.artistId.lastname
                .normalize()
                .localeCompare(b.artistId.lastname.normalize()) === 0
            ) {
              return a.artistId.firstname
                .normalize()
                .localeCompare(b.artistId.firstname.normalize());
            } else {
              return a.artistId.lastname
                .normalize()
                .localeCompare(b.artistId.lastname.normalize());
            }
          });
          temp.push({
            cat: res[index].cat,
            data: orderedArtist,
          });
        }

        temp.sort((a, b) => {
          return (
            sortingSeqMedical.indexOf(a.cat) - sortingSeqMedical.indexOf(b.cat)
          );
        });

        if (isMedical.includes(props.activeBtn)) {
          let gNovel = temp.filter(
            (obj) => obj.cat === props.medical.toUpperCase()
          );
          gNovel = gNovel[0].data.map((e) => e.artistId);
          setArtistData(
            sortAlphaOrder(
              gNovel !== undefined ? (res.length > 0 ? gNovel : []) : []
            )
          );
        } else {
          let gNovel = temp;
          let allGraphArtist = gNovel
            .map((e) => e.data.map((art) => art.artistId))
            .flat();
          const uniqueGraphicArtists = allGraphArtist.reduce(
            (accumulator, current) => {
              if (!accumulator.find((item) => item._id === current._id)) {
                accumulator.push(current);
              }
              return accumulator;
            },
            []
          );
          /* 
          const uniqueArtists = gNovel[0].data.reduce((accumulator, current) => {
            if (!accumulator.find((item) => item.tweet.author.id === current.tweet.author.id)) {
              accumulator.push(current);
            }
            return accumulator;
          }, []); */

          setArtistData(
            sortAlphaOrder(
              uniqueGraphicArtists !== undefined
                ? res.length > 0
                  ? uniqueGraphicArtists
                  : []
                : []
            )
          );
        }
      });
    } else if (props.activeBtn === "motion") {
      getArtistCategoryTypeOne({ keyword: "3D Rendering", type: 1 }).then(
        (res) => {
          setArtistData(
            sortAlphaOrder(res !== undefined ? (res.length > 0 ? res : []) : [])
          );
        }
      );
    } else if (props.activeBtn === "bipoc") {
      setBipocMenuOpen(true);
      if (props.currentBipoc !== "BIPOC") {
        switch (props.currentBipoc) {
          case "black":
            getBipocBlack().then((res) => {
              let orderedArtist = res?.sort((a, b) => {
                if (
                  a.artistData.lastname
                    .normalize()
                    .localeCompare(b.artistData.lastname.normalize()) === 0
                ) {
                  return a.artistData.firstname
                    .normalize()
                    .localeCompare(b.artistData.firstname.normalize());
                } else {
                  return a.artistData.lastname
                    .normalize()
                    .localeCompare(b.artistData.lastname.normalize());
                }
              });
              let allBipoc = orderedArtist.map((val) => val.artistData);
              setArtistData(
                sortAlphaOrder(
                  allBipoc !== undefined ? (res.length > 0 ? allBipoc : []) : []
                )
              );
            });
            break;

          case "asianArtist":
            getBipocAsian().then((res) => {
              let orderedArtist = res?.sort((a, b) => {
                if (
                  a.artistData.lastname
                    .normalize()
                    .localeCompare(b.artistData.lastname.normalize()) === 0
                ) {
                  return a.artistData.firstname
                    .normalize()
                    .localeCompare(b.artistData.firstname.normalize());
                } else {
                  return a.artistData.lastname
                    .normalize()
                    .localeCompare(b.artistData.lastname.normalize());
                }
              });
              let allBipoc = orderedArtist.map((val) => val.artistData);
              setArtistData(
                sortAlphaOrder(
                  allBipoc !== undefined ? (res.length > 0 ? allBipoc : []) : []
                )
              );
            });
            break;
          case "latinoArtist":
            getBipocLatino().then((res) => {
              let orderedArtist = res?.sort((a, b) => {
                if (
                  a.artistData.lastname
                    .normalize()
                    .localeCompare(b.artistData.lastname.normalize()) === 0
                ) {
                  return a.artistData.firstname
                    .normalize()
                    .localeCompare(b.artistData.firstname.normalize());
                } else {
                  return a.artistData.lastname
                    .normalize()
                    .localeCompare(b.artistData.lastname.normalize());
                }
              });
              let allBipoc = orderedArtist.map((val) => val.artistData);
              setArtistData(
                sortAlphaOrder(
                  allBipoc !== undefined ? (res.length > 0 ? allBipoc : []) : []
                )
              );
            });
            break;
          case "centralAsianArtist":
            getBipocCentralAsia().then((res) => {
              let orderedArtist = res?.sort((a, b) => {
                if (
                  a.artistData.lastname
                    .normalize()
                    .localeCompare(b.artistData.lastname.normalize()) === 0
                ) {
                  return a.artistData.firstname
                    .normalize()
                    .localeCompare(b.artistData.firstname.normalize());
                } else {
                  return a.artistData.lastname
                    .normalize()
                    .localeCompare(b.artistData.lastname.normalize());
                }
              });
              let allBipoc = orderedArtist.map((val) => val.artistData);
              setArtistData(
                sortAlphaOrder(
                  allBipoc !== undefined ? (res.length > 0 ? allBipoc : []) : []
                )
              );
            });
            break;
          case "indigenousArtist":
            getBipocIndigenous().then((res) => {
              let orderedArtist = res?.sort((a, b) => {
                if (
                  a.artistData.lastname
                    .normalize()
                    .localeCompare(b.artistData.lastname.normalize()) === 0
                ) {
                  return a.artistData.firstname
                    .normalize()
                    .localeCompare(b.artistData.firstname.normalize());
                } else {
                  return a.artistData.lastname
                    .normalize()
                    .localeCompare(b.artistData.lastname.normalize());
                }
              });
              let allBipoc = orderedArtist.map((val) => val.artistData);
              setArtistData(
                sortAlphaOrder(
                  allBipoc !== undefined ? (res.length > 0 ? allBipoc : []) : []
                )
              );
            });
            break;
          default:
            break;
        }
      } else {
        Promise.all([
          getBipocBlack(),
          getBipocAsian(),
          getBipocLatino(),
          getBipocCentralAsia(),
          getBipocIndigenous(),
        ]).then((val) => {
          let allBipoc = val.map((e) => e.map((val) => val.artistData)).flat();
          setArtistData(
            sortAlphaOrder(
              allBipoc !== undefined ? (val.length > 0 ? allBipoc : []) : []
            )
          );
        });
      }
    } else if (
      isGraphical.includes(props.activeBtn) ||
      props.activeBtn === "graphicNovel"
    ) {
      setGraphicMenuOpen(true);
      getGraphicCovers().then((res) => {
        let temp = [];
        for (let index = 0; index < res.length; index++) {
          let orderedArtist = res[index].data?.sort((a, b) => {
            if (
              a.artistId.lastname
                .normalize()
                .localeCompare(b.artistId.lastname.normalize()) === 0
            ) {
              return a.artistId.firstname
                .normalize()
                .localeCompare(b.artistId.firstname.normalize());
            } else {
              return a.artistId.lastname
                .normalize()
                .localeCompare(b.artistId.lastname.normalize());
            }
          });
          temp.push({
            cat: res[index].cat,
            data: orderedArtist,
          });
        }

        temp.sort((a, b) => {
          return sortingSeq.indexOf(a.cat) - sortingSeq.indexOf(b.cat);
        });

        if (isGraphical.includes(props.activeBtn)) {
          let gNovel = temp.filter(
            (obj) => obj.cat === props.graphicNovel.toUpperCase()
          );
          gNovel = gNovel[0].data.map((e) => e.artistId);
          setArtistData(
            sortAlphaOrder(
              gNovel !== undefined ? (res.length > 0 ? gNovel : []) : []
            )
          );
        } else {
          let gNovel = temp;
          let allGraphArtist = gNovel
            .map((e) => e.data.map((art) => art.artistId))
            .flat();
          const uniqueGraphicArtists = allGraphArtist.reduce(
            (accumulator, current) => {
              if (!accumulator.find((item) => item._id === current._id)) {
                accumulator.push(current);
              }
              return accumulator;
            },
            []
          );
          /* 
          const uniqueArtists = gNovel[0].data.reduce((accumulator, current) => {
            if (!accumulator.find((item) => item.tweet.author.id === current.tweet.author.id)) {
              accumulator.push(current);
            }
            return accumulator;
          }, []); */

          setArtistData(
            sortAlphaOrder(
              uniqueGraphicArtists !== undefined
                ? res.length > 0
                  ? uniqueGraphicArtists
                  : []
                : []
            )
          );
        }
      });
    } else if (props.activeBtn === "properties") {
      getAllProperties().then((res) => {
        const tempPropertiesArtists = Object.keys(res).map((artistName) => {
          return {
            fullName: res[artistName].fullName,
            firstname: res[artistName].firstname,
            lastname: res[artistName].lastname,
          };
        });
        setArtistData(sortAlphaOrder(tempPropertiesArtists));
      });
    } else if (props.activeBtn === "motions") {
      getMotions().then((res) => {
        const motionsArtist = res.map((artist) => {
          return {
            fullName: artist.artistData.fullName,
            firstname: artist.artistData.firstname,
            lastname: artist.artistData.lastname,
          };
        });
        setArtistData(sortAlphaOrder(motionsArtist));
      });
    } else if (props.activeBtn === "detailedPage") {
      let localPrevCate =
        localStorage.getItem("Category") === "cgi" ||
        localStorage.getItem("Category") === "Motion"
          ? "3D Rendering"
          : localStorage.getItem("Category") === "Illustration" ||
            localStorage.getItem("Category") === "Illustration-artists"
          ? "ILLUSTRATION"
          : localStorage.getItem("Category");

      if (props.currentGraphical !== "") {
        if (props.currentGraphical === "GRAPHIC NOVEL") {
          localPrevCate = "Graphic Novel";
        } else {
          localPrevCate = props.currentGraphical.toUpperCase();
        }
      }

      if (localPrevCate === "none") {
        localPrevCate = "ILLUSTRATION";
      }

      if (props.medical !== "") {
        if (props.medical === "MEDICAL") {
          localPrevCate = "ILLUSTRATION";
        } else {
          localPrevCate = props.medical.toUpperCase();
        }
      }

      let bipocCat =
        localStorage.getItem("Bipoc") === "none"
          ? null
          : localStorage.getItem("Bipoc");

      bipocCat =
        bipocCat === "LatinoArtist"
          ? "Latino"
          : bipocCat === "CentralAsianArtist"
          ? "Central Asia"
          : bipocCat === "AsianArtist"
          ? "Asian"
          : bipocCat === "IndigenousArtist"
          ? "Indigenous"
          : bipocCat;

      getArtistCategoryTypeOne(
        bipocCat
          ? { keyword: localPrevCate, type: { $ne: 2 }, bipocCat }
          : { keyword: localPrevCate, type: { $ne: 2 } }
      ).then((res) => {
        setArtistData(
          sortAlphaOrder(res !== undefined ? (res.length > 0 ? res : []) : [])
        );
      });
    }
    getCategoryTypeOne().then((res) => {
      setKeywordReducer(
        sortAlphaOrderKeyword(
          res !== undefined ? (res.length > 0 ? res : []) : []
        )
      );
    });

    currArtist = ArtistDataAPI.artistData.filter(
      (artist) =>
        artist.firstname.toLowerCase() + artist.lastname.toLowerCase() ===
        props.currentArtist
    );
  }, [localStorage.getItem("Category"), props.activeBtn]);

  useEffect(() => {
    if (search2) {
      let category = "";
      if (search2List.includes(search2)) {
        category = search2.replace("_", "/");
      } else {
        category = search2.replace("_", " ");
        category = category.replace("_", " ");
      }
      if (
        search2 === "portfolio" ||
        search2 === "bio" ||
        search2 === "publishedworks" ||
        search2 === "personalworks" ||
        search2 === "properties" ||
        search2 === "motion" ||
        search2 === "awards&press"
      ) {
        category = "none";
      }
      localStorage.setItem("Category", category);
    }

    return () => {
      if (localStorage.getItem("routePaths")) {
        let route = JSON.parse(localStorage.getItem("routePaths"));
        if (route.find((obj) => obj.artistExist == true)) {
          route.pop();
          localStorage.setItem("routePaths", JSON.stringify(route));
        }
      }
    };
  }, [search2]);

  const mapBipoc = (bipocName) => {
    const bipocObject = {
      black: "Black",
      Black: "Black",
      asianArtist: "Asian",
      AsianArtist: "Asian",
      latinoArtist: "Latino/Latina",
      LatinoArtist: "Latino/Latina",
      centralAsianArtist: "Central Asian",
      CentralAsianArtist: "Central Asian",
      indigenousArtist: "Indigenous",
      IndigenousArtist: "Indigenous",
      BIPOC: "BIPOC",
    };

    return bipocObject[bipocName]?.toUpperCase() || "BIPOC";
  };

  const toggleBipocMenu = (e) => {
    let toggle = !bipocMenuOpen;

    setBipocMenuOpen(toggle);
  };

  const toggleGraphicMenu = (e) => {
    let toggle = !graphicMenuOpen;

    setGraphicMenuOpen(toggle);
  };
  const toggleMedicalMenu = (e) => {
    let toggle = !medicalMenuOpen;

    setMedicalMenuOpen(toggle);
  };

  return (
    <div
      id="w-node-_783b3b4a-9daa-ecbf-356e-d84dbb36f4cc-bb36f4cc"
      className="respdivhide"
    >
      <h3
        className="homeh3"
        style={
          props.activeBtn === "detailedPage" &&
          ((localStorage.getItem("routePaths") &&
            JSON.parse(localStorage.getItem("routePaths"))[1]?.val !==
              "Categories") ||
            localStorage.getItem("Category") === "none")
            ? { textDecorationLine: "none" }
            : {
                color: "#000000",
                fontFamily: "Montserrat, sans-serif",
                textUnderlineOffset: "5px",
                fontWeight: 800,
                marginBottom: "5px",
              }
        }
      >
        {props.activeBtn === "categories"
          ? "CATEGORIES"
          : localStorage.getItem("routePaths") &&
            JSON.parse(localStorage.getItem("routePaths"))[1]?.val ===
              "Categories"
          ? null
          : props.activeBtn === "detailedPage"
          ? "SELECT BY DIVISION"
          : "DIVISIONS"}
      </h3>
      {pages == "categories" ? (
        keywordReducer?.length > 0 ? (
          keywordReducer?.map((item, key) => (
            <div key={key}>
              {item.type === 1 ? (
                <Link
                  to={
                    item.keyword.includes("/")
                      ? "/categories/" + item.keyword.replace(/\//g, "_")
                      : "/categories/" + item.keyword.replace(/\s/g, "_")
                  }
                  onClick={() => {}}
                  className={
                    "divisionslink" +
                    (localStorage.getItem("Category") === item.keyword
                      ? " w--current"
                      : "")
                  }
                >
                  <span className="sidebarlink">
                    {item.keyword.toUpperCase()}
                    <br />
                  </span>
                </Link>
              ) : (
                ""
              )}
            </div>
          ))
        ) : (
          ""
        )
      ) : (
        <div className="allartist v2">
          {props.currentCat !== "none" &&
          !pagesWithSideMenu.includes(props.currentCat) &&
          !(
            JSON.parse(localStorage.getItem("routePaths")) &&
            JSON.parse(localStorage.getItem("routePaths"))[1]?.val ===
              "Graphic Novel"
          ) ? (
            <div>
              <Link style={{ lineHeight: "0" }} to={"/categories"}>
                <span
                  className="homeh3"
                  style={{
                    color: "#000000",
                    fontFamily: "Montserrat, sans-serif",
                    textUnderlineOffset: "5px",
                    fontWeight: 800,
                    lineHeight: 0,
                    paddingBottom: "5px",
                  }}
                >
                  CATEGORIES
                  <br />
                </span>
              </Link>
              <Link
                to={
                  JSON.parse(localStorage.getItem("routePaths"))
                    ? JSON.parse(localStorage.getItem("routePaths"))[2]?.link
                    : ""
                }
              >
                <span
                  className="homeh3"
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    textUnderlineOffset: "5px",
                    fontWeight: 800,
                    paddingBottom: "5px",
                  }}
                >
                  {localStorage.getItem("Category")
                    ? localStorage.getItem("Category").toUpperCase()
                    : ""}
                  <br />
                </span>
              </Link>
              <br />
              <br />
            </div>
          ) : (
            <div>
              <Link
                to="/illustration-artists"
                className={
                  "divisionslink" +
                  (props.activeBtn === "illustration-artists" ||
                  localStorage.getItem("Category") === "Illustration" ||
                  localStorage.getItem("Category") === "Illustration-artists"
                    ? " w--current"
                    : "")
                }
              >
                <span className="sidebarlink">
                  ILLUSTRATION
                  <br />
                </span>
              </Link>
              <div>
                <Link
                  to="/bipoc"
                  className={
                    "divisionslink" +
                    (props.activeBtn === "bipoc" ||
                    bipocPagesCurrentStyle.includes(
                      localStorage.getItem("Bipoc")
                    ) ||
                    (props.currentBipoc !== "none" &&
                      props.currentBipoc !== null)
                      ? " w--current"
                      : "")
                  }
                >
                  <span className="sidebarlink">BIPOC</span>
                </Link>
                <span onClick={toggleBipocMenu} style={{ cursor: "pointer" }}>
                  {" "}
                  &#8744; <br />
                </span>
              </div>
              {bipocMenuOpen ? (
                <>
                  <Link
                    to="/bipoc/black"
                    className={
                      "divisionslink ml-2" +
                      (props.currentBipoc === "black" ||
                      props.currentBipoc === "Black"
                        ? " w--current"
                        : "")
                    }
                  >
                    <span className="sidebarlink">
                      BLACK
                      <br />
                    </span>
                  </Link>
                  <Link
                    to="/bipoc/asianArtist"
                    className={
                      "divisionslink ml-2" +
                      (props.currentBipoc === "asianArtist" ||
                      props.currentBipoc === "AsianArtist" ||
                      props.currentBipoc === "Asian"
                        ? " w--current"
                        : "")
                    }
                  >
                    <span className="sidebarlink">
                      ASIAN
                      <br />
                    </span>
                  </Link>
                  <Link
                    to="/bipoc/latinoArtist"
                    className={
                      "divisionslink ml-2" +
                      (props.currentBipoc === "latinoArtist" ||
                      props.currentBipoc === "LatinoArtist" ||
                      props.currentBipoc === "Latino/Latina"
                        ? " w--current"
                        : "")
                    }
                  >
                    <span className="sidebarlink">
                      LATINO/LATINA
                      <br />
                    </span>
                  </Link>
                  <Link
                    to="/bipoc/centralAsianArtist"
                    className={
                      "divisionslink ml-2" +
                      (props.currentBipoc === "centralAsianArtist" ||
                      props.currentBipoc === "CentralAsianArtist" ||
                      props.currentBipoc === "Central Asian"
                        ? " w--current"
                        : "")
                    }
                  >
                    <span className="sidebarlink">
                      CENTRAL ASIAN
                      <br />
                    </span>
                  </Link>
                  <Link
                    to="/bipoc/indigenousArtist"
                    className={
                      "divisionslink ml-2" +
                      (props.currentBipoc === "indigenousArtist" ||
                      props.currentBipoc === "IndigenousArtist" ||
                      props.currentBipoc === "Indigenous"
                        ? " w--current"
                        : "")
                    }
                  >
                    <span className="sidebarlink">
                      INDIGENOUS
                      <br />
                    </span>
                  </Link>
                </>
              ) : null}
              <div>
                <Link
                  to="/graphicNovel"
                  className={
                    "divisionslink" +
                    (props.activeBtn === "graphicNovel" ||
                    isGraphical.includes(props.activeBtn) ||
                    props.currentGraphical
                      ? " w--current"
                      : "")
                  }
                >
                  <span className="sidebarlink">GRAPHIC NOVEL</span>
                </Link>
                <span onClick={toggleGraphicMenu} style={{ cursor: "pointer" }}>
                  {" "}
                  &#8744; <br />
                </span>
              </div>
              {graphicMenuOpen ? (
                <>
                  <Link
                    to="/graphicNovel/covers"
                    className={
                      "divisionslink ml-2" +
                      (props.activeBtn === "covers" ||
                      props.currentGraphical === "covers"
                        ? " w--current"
                        : "")
                    }
                  >
                    <span className="sidebarlink">
                      COVERS
                      <br />
                    </span>
                  </Link>
                  <Link
                    to="/graphicNovel/sequential"
                    className={
                      "divisionslink ml-2" +
                      (props.activeBtn === "sequential" ||
                      props.currentGraphical === "sequential"
                        ? " w--current"
                        : "")
                    }
                  >
                    <span className="sidebarlink">
                      SEQUENTIAL
                      <br />
                    </span>
                  </Link>
                  <Link
                    to="/graphicNovel/inking"
                    className={
                      "divisionslink ml-2" +
                      (props.activeBtn === "inking" ||
                      props.currentGraphical === "inking"
                        ? " w--current"
                        : "")
                    }
                  >
                    <span className="sidebarlink">
                      INKING
                      <br />
                    </span>
                  </Link>
                  <Link
                    to="/graphicNovel/coloring"
                    className={
                      "divisionslink ml-2" +
                      (props.activeBtn === "coloring" ||
                      props.currentGraphical === "coloring"
                        ? " w--current"
                        : "")
                    }
                  >
                    <span className="sidebarlink">
                      COLORING
                      <br />
                    </span>
                  </Link>
                  <Link
                    to="/graphicNovel/lettering"
                    className={
                      "divisionslink ml-2" +
                      (props.activeBtn === "lettering" ||
                      props.currentGraphical === "lettering"
                        ? " w--current"
                        : "")
                    }
                  >
                    <span className="sidebarlink">
                      LETTERING
                      <br />
                    </span>
                  </Link>
                </>
              ) : null}

              <a
                href="https://kidshannon.com"
                target="_blank"
                className="sidebarlink"
              >
                KIDSHANNON
                <br />
              </a>
              <Link
                to="/photography"
                className={
                  "divisionslink" +
                  (props.activeBtn === "photography" ||
                  localStorage.getItem("Category") === "Photography"
                    ? " w--current"
                    : "")
                }
              >
                <span className="sidebarlink">
                  PHOTOGRAPHY
                  <br />
                </span>
              </Link>
              <div>
                <Link
                  to="/medical"
                  className={
                    "divisionslink" +
                    (props.activeBtn === "medical" ||
                    localStorage.getItem("Category") === "Medical"
                      ? " w--current"
                      : "")
                  }
                >
                  <span className="sidebarlink">MEDICAL</span>
                </Link>
                <span onClick={toggleMedicalMenu} style={{ cursor: "pointer" }}>
                  {" "}
                  &#8744; <br />
                </span>
              </div>
              {medicalMenuOpen ? (
                <>
                  <Link
                    to="/medical/general"
                    className={
                      "divisionslink ml-2" +
                      (props.activeBtn === "general" ||
                      props.medical === "general"
                        ? " w--current"
                        : "")
                    }
                  >
                    <span className="sidebarlink">
                      GENERAL
                      <br />
                    </span>
                  </Link>

                  <Link
                    to="/medical/technical"
                    className={
                      "divisionslink ml-2" +
                      (props.activeBtn === "technical" ||
                      props.medical === "technical"
                        ? " w--current"
                        : "")
                    }
                  >
                    <span className="sidebarlink">
                      TECHNICAL
                      <br />
                    </span>
                  </Link>
                </>
              ) : null}
              <Link
                to="/motion"
                className={
                  "divisionslink" +
                  (props.activeBtn === "motion" ||
                  localStorage.getItem("Category") === "Motion"
                    ? " w--current"
                    : "")
                }
              >
                <span className="sidebarlink">MOTION</span>
              </Link>
              <br />
              <br />
              <br />
              <br />
            </div>
          )}

          {/* {pages == "categories" && keywordReducer?.length > 0 ? keywordReducer?.map((item,key)=>(
              <div key={key}>
              {item.type === 1?(<Link to={"/divisions/"+item.keyword}  className={"divisionslink"+(props.activeBtn === item.keyword?" w--current":"")}><span className="sidebarlink">{item.keyword.toUpperCase()}<br /></span></Link>):""}
              
              </div>
          )):""} */}
          <h3
            className="homeh3"
            style={{ textDecorationLine: "none", marginBottom: "5px" }}
          >
            {" "}
            {localStorage.getItem("Category") !== "none"
              ? props.currentCat?.toUpperCase() + " - ARTISTS" || "ARTISTS"
              : bipocPagesCurrentStyle.includes(props.currentBipoc)
              ? mapBipoc(props.currentBipoc) + " - ARTISTS"
              : isGraphical.includes(props.graphicNovel)
              ? props.graphicNovel.toUpperCase() + " - ARTISTS" || "ARTISTS"
              : props.activeBtn === "properties"
              ? "PROPERTIES"
              : "SELECT BY ARTIST"}
          </h3>
          {alpha.map((item, key) => (
            <div key={key}>
              {artistData[item] !== undefined ? (
                <div key={key} className="alphabets">
                  {item}
                  <br />
                  {artistData[item].map((item1, key1) => (
                    <div key={key1}>
                      {props.activeBtn === "detailedPage" ? (
                        <Link
                          to={"../" + item1.fullName}
                          className={
                            "sidebarlink " +
                            (item1.firstname.toLowerCase() +
                              item1.lastname.toLowerCase() ===
                            props.currentArtist?.toLowerCase()
                              ? "currentSidebar"
                              : "")
                          }
                        >
                          {item1.firstname.toUpperCase()}{" "}
                          {item1.lastname.toUpperCase()}
                          <br />
                        </Link>
                      ) : (
                        <Link
                          to={item1.fullName}
                          className={
                            "sidebarlink " +
                            (item1.firstname.toLowerCase() +
                              item1.lastname.toLowerCase() ===
                            props.currentArtist?.toLowerCase()
                              ? "currentSidebar"
                              : "")
                          }
                        >
                          {item1.firstname.toUpperCase()}{" "}
                          {item1.lastname.toUpperCase()}
                          <br />
                        </Link>
                      )}
                    </div>
                  ))}
                  <br />
                </div>
              ) : (
                ""
              )}
            </div>
          ))}
          <br />
        </div>
      )}
    </div>
  );
}

export default DivisionSideBar;
